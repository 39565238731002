// Here you can add other styles

.sidebar-toggler {
  &::before {
    -webkit-mask: var(--#{$prefix}sidebar-toggler-bg) no-repeat center;
  }
}

.sidebar-nav {
  .nav-group-toggle {
    &::after {
      -webkit-mask-image: var(--#{$prefix}sidebar-nav-group-indicator-icon);
    }
  }
}
